import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Link } from '@material-ui/core';
import * as API from '../../util/FAPI';
import { API_AUTH_SIGNED_IN, ZTRUCKING_WEBSITE } from '../../util/Const';
import { connect } from 'react-redux'
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import Main from '../app'
import { UPDATE, updateAuthToken, getAuthToken } from '../../util/FAPI';
import { isProfileActive } from '../../util/ACL';

import logo from './logo_zt_square.png';


const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${'/truck.jpg'})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  centralized: {
    margin: 'auto',
    backgroundColor: "#F8F8FF",
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    fontFamily: "'Roboto', sans-serif",
  },
  logo: {
    width: '100px',
    height: '100px',
    marginBottom: theme.spacing(2),
  },
  headingLink: {
    margin: theme.spacing(2, 0),
    fontWeight: 'bold',
    textAlign: 'center',
  },
  link: {
    fontSize: '16px',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  secondaryLink: {
    fontSize: '16px',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1, 0),
  },
  formControlLabel: {
    margin: theme.spacing(1, 0),
  },
  submitButton: {
    margin: theme.spacing(2, 0, 2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  errorMessage: {
    margin: theme.spacing(2, 0),
    textAlign: 'center',
    color: 'red',
    letterSpacing: '2px',
    fontSize: "15px"
  },
  footer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
});



class SignIn extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loginAs: 'ADMIN',
      errLogin: false
    }

  }

  updateLoginAs = event => {
    let loginAs = event.target.value
    this.setState({ loginAs: loginAs });
  };


  routeTosignUp = () => {
    this.props.dispatchSignUp()
    window.location.href = '/signupm'
  }

  routeToForgotCredential = () => {
    // this.props.dispatchSignUp()
    window.location.href = '/forgotcred'
  }




  onSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    UPDATE(API_AUTH_SIGNED_IN, 'post', data).then(res => {
      if (res.data.profileType == 'DRIVER') {
        throw 'Access Denied!'
      }
      if (!isProfileActive(res.data)) {
        throw 'Access Denied!'
      }

      updateAuthToken(res)
      // @deprecated
      let profile = API.GET_RESULT(res)
      this.props.dispatchSignedIn(getAuthToken(), profile)
      API.validateSubscriptionWithRerouteToPaymentPage(profile, this.props)

    }).catch(err => {
      this.setState({ errLogin: true });
    })
  }

  render() {
    const { classes } = this.props;

    if (!!getAuthToken()) {
      return (<Main />)
    }

    return (
      <Grid container component="main" className={classes.root} style={{ backgroundImage: 'url(/truck.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square className={classes.centralized}   >

          <div className={classes.paper}>
            <img src={logo} alt='Your Logo' className={classes.logo} />
            <Typography component="h1" variant="h5" className={classes.headingLink} style={{ fontFamily: "'Roboto', sans-serif" }}>
              <Link href={ZTRUCKING_WEBSITE} className={classes.link}>
                Elevate Your Logistics: AI-Driven Software for Daily FMCSA Audits & Comprehensive Carrier Management Software!

              </Link>
            </Typography>

            <form className={classes.form} onSubmit={this.onSubmit}>
              <Grid container direction='column'>

                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="profileEmail">Username</InputLabel>
                  <Input id="profileEmail" name="profileEmail" autoComplete="profileEmail" autoFocus />
                </FormControl>

                <FormControl margin="normal" fullWidth>
                  <InputLabel htmlFor="profilePassword">Password</InputLabel>
                  <Input name="profilePassword" type="password" id="profilePassword" autoComplete="current-profilePassword" />
                </FormControl>

                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />

                <Button
                  type="submit"
                  color='primary'
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                  style={{ marginBottom: '16px' }}>
                  Sign in
                </Button>

                <Link
                  component="button"
                  variant="body2"
                  className={classes.link}
                  onClick={this.routeToForgotCredential}>
                  Forgot Password?
                </Link>

                <Typography variant="body2" className={classes.secondaryLink} style={{ textAlign: 'center' }} onClick={this.routeTosignUp}>
                  Not a member? <Link className={classes.link} component="button">Sign Up</Link>
                </Typography>

                {this.state.errLogin &&
                  <Typography variant="body2" className={classes.errorMessage} style={{ textAlign: 'center', marginTop: '16px' }}>
                    Trouble signing in? Double-check your credentials, as usernames and passwords may be case-sensitive.
                    Try again or click Forgot Password to reset it.<br />
                    If you continue to see this message, please contact us at service@ztrucking.com, and one of our representatives will assist you shortly!
                  </Typography>
                  // <Typography variant="body2" className={classes.errorMessage} style={{ textAlign: 'center', marginTop: '16px' }}>
                  //   Trouble signing in? Try again or click Forgot Password to reset it.<br/>
                  //   If you continue to see this message, please contact us at service@ztrucking.com, and one of our representatives will contact you shortly!
                  // </Typography>
                }
              </Grid>
              <div className={classes.footer}>
                <Typography variant="body2" align="center" style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgba(0, 0, 0, 0.54)' }}>
                  &copy; Copyright <a href="https://www.ztrucking.com" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>ZTRUCKING GROUP CORP.</a> &nbsp;| &nbsp;
                  <a href="https://www.ztrucking.com/terms-and-condition" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Terms of Use</a>  &nbsp;|  &nbsp;
                  <a href="https://www.ztrucking.com/privacy-policy" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Privacy Policy</a>
                </Typography>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }

}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(SignIn))

// export default withStyles(styles)(SignIn);