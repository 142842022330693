import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

const FLinearProgressBar = ({ completedSteps, totalSteps }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (totalSteps > 0) {
      const progressValue = (completedSteps / totalSteps) * 100;
      setProgress(progressValue);
    }
  }, [completedSteps, totalSteps]);

  const progressColor = progress === 0 ? '#d0e7f9' : '#3f51b5'; 

  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <LinearProgress
        variant="determinate"
        value={progress}
        style={{
          height: 22,
          width: '80%',
          minWidth: 80,
          marginRight: 10,
          backgroundColor: progress === 0 ? '#d0e7f9' : '#e0e0e0',
        }}
        color={progress === 0 ? 'default' : 'primary'} 
      />
    </div>
  );
};

export default FLinearProgressBar;
