import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

export const POLICY_PRETTY_NAME = {
  1000: {
    'title': 'Company Owner', 
    'message': <ul>
                  <li>Unlimited access!</li>
                </ul>
  },
  1100: {
    'title': 'Driver Profile', 
    'message': <ul>
                  <li>Driver App: Driver & Driver's documents</li>
                  <li>Driver App: Employment Application</li>
                  <li>Driver App: Submitted Documents</li>
               </ul>
  },
  2000: {
    'title': 'Company Assets Record', 
    'message': <ul>
                  <li>Admin: Carrier & Carrier's documents</li>
                  {/* <li>Admin: Driver & Driver's documents</li> */}
                  <li>Admin: Vehicle & Vehicle's documents</li>
                  <li>Admin: Dispatcher & Dispatcher's documents</li>
                  <li>Admin: Fuel Card & Fuel card's documents</li>
                  <li>Admin: Integration / e.g. Motive Integration</li>
               </ul>
  },
  3000: {
    'title': 'Fleets  & Customer Records', 
    'message': <ul>
                  <li>Fleet: Fleets & Fleet's documents</li>
                  <li>Fleet: Mileage / Trip Imports e.g Keeptruckin </li> 
                  <li>Fleet: Customer & Customer's documents</li>
               </ul>
  },
  4000: {
    'title': 'Expense Records', 
    'message': <ul>
                  <li>Expense: Expenses </li> 
                  <li>Expense: Fuel Imports e.g. FleetOne </li>     
               </ul>
  },
  // 5000: {
  //   'title': 'Fuel & Mileage Imports', 
  //   'message': <ul>
  //                 <li>Fleet: Fuel Imports e.g. FleetOne </li> 
  //                 <li>Fleet: Mileage / Trip Imports e.g Keeptruckin </li>  
  //              </ul>
  // },
  6000: {
    'title': 'Finance & Accounting', 
    'message': <ul>
                  <li>Finance: Paycheck </li> 
                  <li>Finance: Invoice </li>
                  <li>Report: Invoice </li>
                  <li>Dashboard: Financial Overview </li>     
               </ul>
  },
  
  7000: {
    'title': 'Safety & Compliance record', 
    'message': <ul>
                  <li>Safety & Compliance: Expiring Documents</li>
                  <li>Safety & Compliance: Expired Documents</li>
                  <li>Safety & Compliance: Missing Documents</li>
                  <li>Safety & Compliance: Drug test records and documents</li>
                  <li>Safety & Compliance: Employment background checks</li>
                  <li>Safety & Compliance: Employment records and documents</li>
                  <li>Safety & Compliance: Residential History records and documents</li>
                  <li>Safety & Compliance: Vehicle Inspection and documents</li>
                  <li>Safety & Compliance: Vehicle Maintenance and documents</li>
                  <li>Safety & Compliance: Accidents/  Violations and documents</li>
                  <li>Safety & Compliance: Driver disciplinary aciton and documents</li>
                  <li>Safety & Compliance: Maintenance Schedule</li>
               </ul>
  },

  8000: {
    'title': 'IFTA reports', 
    'message': <ul>
                  <li>Report: Ifta  Summary</li>
              </ul>
  },
  9000: {
    'title': 'Company Assets Reports', 
    'message': <ul>
                  <li>Report: Driver reports</li>
                  <li>Report: Vehicle reports</li>
              </ul>
  },

  10000: {
    'title': 'Fleet Reports', 
    'message': <ul>
                  <li>Report: Fleets</li>
              </ul>
  },
  11000: {
    'title': 'Expense Report ', 
    'message': <ul>
                  <li>Report: Expenses</li>
              </ul>
  },
 
  12000: {
    'title': 'Safety Report  ', 
    'message': <ul>
                  <li>Report: Maintenance Record</li>
                  <li>Report: Maintenance Schedule Report</li>
                  <li>Report: Driver Disciplinary Event Report</li>
              </ul>
  },
  13000: {
    'title': 'Contacts & Contact Documents  ', 
    'message': <ul>
                  <li>Admin: Contacts & Contacts's documents</li>
              </ul>
  },
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function FPermissionPolicyTitle(props) {
  return (
    <div>
      <HtmlTooltip
        title={
          <div>
            {POLICY_PRETTY_NAME[props.policy_id].message}
          </div>
        }
        >
       
       <Button color='primary'>{POLICY_PRETTY_NAME[props.policy_id].title}</Button>
      </HtmlTooltip>
    </div>
  );
}