import React from "react";
import { GET, UPDATE, GET_RESULT } from "./FAPI";
import { isValid, isNotNullOrEmpty } from "./Validator";
import { getErrroMessage, showSuccessNotification, showErroNotification } from "../components/widgets/FMessage";

import { getModuleTitle, PROFILE_UPDATE_MODULES } from "./Const";
import { VALIDATE_REQUIRED } from "./ValidatorConst";
import FButton from "../components/widgets/FButton";
import { FRouteBackLink } from "../components/widgets/FRouterLink";
import { has_create_permission, has_edit_permission } from "./ACL";
import { Grid } from "@material-ui/core";

export default class FDetailComponent extends React.Component {
    constructor(props, url, module, addProps, addUrlSlash=false, idFromProps=false) {
        super(props)
        let id = props.match && props.match.params &&  props.match.params.id;
        let defaultUrl = url
        let isNew = id === 'add'
        let uri= isNew ? url : url + id
        if (!!props.detailID){
            uri = url + props.detailID
        }
        // if (!!addUrlSlash) uri = uri+='/'

        this.state = {
            showAddCompany: false,
            showAddCustomer: false,
            loading: false,
            isNew: isNew,
            method: !isNew ? 'patch' : 'post',
            url: uri,
            id: id,
            defaultUrl: defaultUrl, 
            detailID: props.detailID, 
            formData: {},
            module: module,
            ...addProps
        }
    }
    
    getGetUrl = () => {
        let id = this.state.detailID || this.state.id
        
        if (!!this.state.getUrl) {
            return this.state.getUrl + id
        }
        return this.state.url
    }

    getPostUrl = () => {
        return this.state.postUrl || this.state.url
    }

    getPatchUrl = () => {
        return this.state.patchUrl || this.state.url
    }
    getSaveMeta = () => {
        if (!!this.state.isNew) {
            return {
                method: "post",
                url: this.getPostUrl()
            }
        }else {
            return {
                method: "patch",
                url: this.getPatchUrl()
            }
        }
    }
    componentDidMount = () => {
        this.fetchDetail()
    }

    componentWillUnmount() {
        this.props.resetServerValidation()
    }

    //TODO(Estif) Revise doing noting
    resetForms = () => {
        this.setState({ formData: {}, data: {}, touched: {} });
    };

    fetchDetail = () => {
        this.props.clearServerError({})
        if (this.state.isNew) return
        GET(this.getGetUrl()).then(res => {
            this.setState({ data:  GET_RESULT(res) })
        }).catch(error => {
            this.props.updateServerError({ msg: getErrroMessage(error) })
        })
    }

    updateValues = (name, obj) => {
        this.setState(prevState => {
            let newState = {
                ...prevState,
                data: {
                    ...prevState.data, ...obj
                },
                touched: {
                    ...prevState.touched,
                    [name]: true
                }
            }
            return newState
        }, () => {
            this.validateSingle(name)
        })
    }

    updateState = (name, obj) => {
        this.setState(prevState => {
            let newState = {
                ...prevState, ...obj
            }
            return newState
        }, () => {
        })
    }

    getExcludeKeys = () => {
     // getFromData will cal lthis to exclude keys runtime, e.g patching exclude some keys
       return []
    }

    getFormData = () => {
        let formData = new FormData();
        formData.append(this.props.ownerType, this.props.ownerId)
        let data = this.state.data
        let touchedData = this.state.touched
        const excludeKeys = this.getExcludeKeys()

        Object.keys(data).forEach(key => {
                let value = undefined
                if (touchedData && touchedData[key]) {
                    if (data[key] && data[key].hasOwnProperty("id")) {
                        value =  data[key]['id']
                        // formData.append(key, data[key]['id'])
                    }
                    else {
                        value =  data[key]
                        // formData.append(key, data[key])
                    }
                    if (!!!excludeKeys.includes(key)){
                        formData.append(key, value)
                    }
                   
                }
        })
        return formData
    }

    onSaveData = (evt) => {
        this.validate().then(succ => {
            this._saveData()
            this.updateProfile()
        }).catch(e => {
        })
    }

    validateSingle = (name) => {
        let isV = !isValid(this.state.data[name], this.state.validators[name])
        this.setState(prevState => {
            let newState = {
                ...prevState,
                errorData: {
                    ...prevState.errorData, ...{ [name]: isV }
                }
            }
            return newState
        })
    }
    
    isAddressValid = (includePlace=false, includeCity=false, includeState=false, includezipCode=false) => {
        if (!isNotNullOrEmpty(this.state.data) || !isNotNullOrEmpty(this.state.data.address)) {
            return true
        }
        let address = this.state.data.address
        if (!!!address) return 
        if (typeof address === 'string' || address instanceof String) {
            address = JSON.parse(address) 
          }
        let isV = true
        if (includePlace) isV = isV && isValid(address.place, [VALIDATE_REQUIRED])
        if (includeCity) isV = isV &&  isValid(address.city, [VALIDATE_REQUIRED])
        if (includeState) isV = isV &&  isValid(address.state, [VALIDATE_REQUIRED])
        if (includezipCode) isV = isV &&  isValid(address.zipCode, [VALIDATE_REQUIRED])
         
        return isV
     }


     onShowAddCompany = (show) => {
        this.setState({showAddCompany: show})
     }
     onShowAddCustomer = (show) => {
        this.setState({showAddCustomer: show})
     }

    getErrorData = () => {
        let errData = {}
        for (const [key, valids] of  Object.entries(this.state.validators)) {
            errData[key] = !isValid(this.state.data[key], valids)
        }
        return errData
    }

    validate = () => {
        return new Promise((resolve, reject) => {
            let errorData = this.getErrorData()
            this.setState(prevState => {
                return { ...prevState, errorData: errorData }
            }, () => {
                let hasError = false
                for (const [key, value] of Object.entries(errorData)) {
                    if (value) {
                        hasError = true
                    }
                }
                // resolve() // TODO(Estifanos) Remove
                hasError ? reject() : resolve()
            })
        })
    }
    
    hasCustomErrors = () => {
       return false
    }
    _saveData = (evt) => {
        this.setState({ loading: true })
        let data = this.getFormData()
        const {url, method} = this.getSaveMeta()
        UPDATE(url, method, data).
            then(response => {
                this.setState({ loading: false })
                // this.props.updateServerSuccess({ msg: getSuccessMessage(response) })
                showSuccessNotification(response, getModuleTitle(this.state.module, 'SAVE'))
            }).catch(error => {
                this.setState({ loading: false })
                showErroNotification(error, getModuleTitle(this.state.module, 'SAVE'))
                // this.props.updateServerError({ msg: getErrroMessage(error) })
            });
    }

    updateProfile = () => {
          if (this.state.isNew && PROFILE_UPDATE_MODULES.includes(this.state.module)) {
            this.props.updateParent && this.props.updateParent({updateProfileData: true})
          }
    }

    getUpdateButton = (profile, policy_id, force_disable_update=false) => {
        // policy_id = CONST.ADMIN_DRIVERS
        const isNewEntry = this.state.isNew ? true : false
        let disabled = true
        if (isNewEntry ) {
            disabled = !has_create_permission(profile, policy_id)
        } else {
            disabled = !has_edit_permission(profile, policy_id)
        }
        if (force_disable_update) {
            disabled = true;
        }
        return (
            <React.Fragment>
                <FButton color='primary'
                    label={isNewEntry ? 'Save' : 'Update'}
                    disabled={disabled}
                    loading={this.state.loading}
                    onClick={this.onSaveData}
                    className={'saveOrUpdateButton'} 
                />
                <Grid item style={{ paddingTop: '10px' }}>
                    <FRouteBackLink 
                      variant={this.props.variant || "outlined"}
                       label ='Cancel'
                       color='secondary' 
                       className={'backButton'} />
                </Grid>
            </React.Fragment>
        )
    }
    getBackButton = (label='back') => {
        return (
            <React.Fragment>
                <Grid  item>
                    <FRouteBackLink variant='outlined' 
                       label ={label}
                       color='secondary' 
                    //    className={'backButton'}
                        />
                </Grid>
            </React.Fragment>
        )
    }

}